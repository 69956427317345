onComplete(function () {
  let prevScrollPos;
  let currentScrollPos = document.documentElement.scrollTop;
  document.querySelector('header.nav-header nav').classList.add('show');
  window.addEventListener('scroll', function () {
    currentScrollPos = document.documentElement.scrollTop;
    if (prevScrollPos > currentScrollPos || prevScrollPos <= 0) {
      document.querySelector('header.nav-header nav').classList.add('show');
    } else {
      document.querySelector('header.nav-header nav').classList.remove('show');
    }
    changeNavbarBg();
    prevScrollPos = currentScrollPos;
  });
  const changeNavbarBg = () => {
    if (currentScrollPos > 50) {
      document.querySelector('header.nav-header').classList.add('bg');
    } else {
      document.querySelector('header.nav-header').classList.remove('bg');
    }
  };
  changeNavbarBg();
});